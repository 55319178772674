<template>
  <div>

    <!-- <vue-recaptcha sitekey="6LeI88oZAAAAALmOb9F9e_jGqm-as5XOS4EPkwZJ" :loadRecaptchaScript="true">
      <button>Click me</button>
    </vue-recaptcha> -->

   <!--  <vue-recaptcha
      :loadRecaptchaScript="true"
      @verify="onVerify"
      @expired="onExpired"
      :sitekey="recaptchaKey">
      <button>Click me</button>
    </vue-recaptcha> -->

    <div ref='main'></div>
    <router-view/>
  </div>
</template>

<script>

  import VueRecaptcha from 'vue-recaptcha';
  import Vue from 'vue';
  import Promise from 'bluebird'
	import { mapActions, mapMutations, mapState } from 'vuex';
  import _ from 'lodash'

  import eventBus from './utils/eventBus'
  import snackbar from '@/components/snackbar.vue'

  export default {
    components: {
      // VueRecaptcha,
    },
    data(){
      return {
        recaptchaKey: "6LeI88oZAAAAALmOb9F9e_jGqm-as5XOS4EPkwZJ",
      }
    },
   	async created(){
      this.loadUserFromLocalStorage();
      if(this.user){
        this.loadProfilePicture();
        this.getLastInvoiceStatus()
        this.fetchDoUnapprovedRecsExist()
      }
      eventBus.$on("createSnackbar", payload => this.createSnackbar(payload))
      await Promise.all([this.getCountries(), this.getMarkers(), this.getTBSTestimonials(), this.getSettings(), this.getCountriesAll()]);

      if (document.referrer) {
        const referrer = new URL(document.referrer).hostname;
        localStorage.setItem("referrer", referrer);
      }

   	},
    computed: {
      ...mapState(['user', 'profilePic']),
      mainRef(){
        return this.$refs.main
      },
    },
   	methods: {
   		...mapActions(["getCountries", 'getMarkers', 'getTBSTestimonials', 'getSettings', 'getCountriesAll', 'loadProfilePicture', 'getLastInvoiceStatus', 'fetchDoUnapprovedRecsExist']),
      ...mapMutations(['updateUser']),
      loadUserFromLocalStorage(){
        // load user from localstorage if exists
        const userStr = localStorage.getItem('user');
        if(userStr){
          this.$store.commit('updateUser',JSON.parse(userStr))
        }
      },
      createSnackbar(payload){

        const ComponentClass = Vue.extend(snackbar)
        const instance = new ComponentClass({
          propsData: payload
        })
        instance.$mount() // pass nothing
        
        this.$refs.main.appendChild(instance.$el)
        setTimeout(() => {
          this.$refs.main.removeChild(instance.$el);
        }, payload.timeoutInMS || 3000)

      },
      onSubmit: function () {
        this.$refs.invisibleRecaptcha.execute()
      },
      onVerify: function (response) {
        console.log('Verify: ' + response)
      },
      onExpired: function () {
        console.log('Expired')
      },
      resetRecaptcha () {
        this.$refs.recaptcha.reset() // Direct call reset method
      }

   	}
  }
</script>

