import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Broadcasts from '@/views/Broadcasts.vue'
import BroadcastDetail from '@/views/BroadcastDetail.vue'
import Parts from '@/views/Parts.vue'
import PartsDetail from '@/views/PartsDetail.vue'
import PartLanding from '@/views/PartLanding.vue'
import Companies from '@/views/Companies.vue'
import CompanyDetail from '@/views/CompanyDetail.vue'
import Inventory from '@/views/Inventory.vue'
import InventoryHits from '@/views/InventoryHits.vue'

import Profile from '@/views/Profile.vue'
import Invoices from '@/views/Invoices.vue'

import TestimonialsPage from '@/views/TestimonialsPage.vue'
import FreeTrial from '@/views/FreeTrial.vue'
import News from '@/views/News.vue'
import About from '@/views/About.vue'
import Contact from '@/views/Contact.vue'
import Help from '@/views/Help.vue'

import Payment from '@/views/Payment.vue'
import Contract from '@/views/Contract.vue'
import Mediakit from '@/views/Mediakit.vue'
import Newsletter from '@/views/Newsletter.vue'
import Services from '@/views/Services.vue'
import Privacy from '@/views/Privacy.vue'

import BroadcastRules from '@/views/BroadcastRules.vue'
import BroadcastSettings from '@/views/BroadcastSettings.vue'
import GradingGuidelines from '@/views/GradingGuidelines.vue'
import RSS from '@/views/RSS.vue'

///media kit sublevels
import AboutTBS from '@/views/mediakit/AboutTBS.vue'
import AboutEBM from '@/views/mediakit/AboutEBM.vue'
import Advertising from '@/views/mediakit/Advertising.vue'
import Social from '@/views/mediakit/Social.vue'
import Videos from '@/views/mediakit/Videos.vue'

import DiamondMembers from '@/views/MembersDiamond.vue'
import GoldMembers from '@/views/MembersGold.vue'
import TrustedMembers from '@/views/MembersTrusted.vue'

import Favorites from '@/views/Favorites.vue'

import PageNotFound from '@/views/PageNotFound.vue'

import store from '../store'
import { property } from 'lodash'

const TITLE_SUFFIX = "The Broker Site";

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: `${TITLE_SUFFIX} Home`,
      metaTags: [
         {
           name: 'description',
           content: `Empowering the Circular IT Industry since 1999`
         },
         {
           // property: 'og:description',
           // content: 'The home page of our example app.'
           name: 'keywords',
           content: 'Circular, IT, Industry, since, 1999, B2B, used, computers, servers, traders, dealers, hardware, broadcasts, brokers, PC, Laptop, mobile, printer'
          }
       ]
    }
  },

  {
    path: '/broadcasts',
    name: 'broadcasts',
    component: Broadcasts,
    meta: {
      title: `Broadcasts`,
      metaTags: [
        {
          name: 'description',
          content: 'Broadcasts from our members looking for (WTB) or offering (WTS) deals'
        },
        {
          name: 'keywords',
          content: 'Broadcasts, Members, Lists, PC, Networking, Laptop, Printer, Server, Storage, Telecom, Tablet, POS, Electronics, GSM, Mobile, Monitor, Software, Components, WTB, WTS'
        }
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "broadcasts" */ '../views/Broadcasts.vue')
  },
  {
    path: '/broadcasts/:id',
    name: 'broadcasts',
    component: BroadcastDetail,
    meta: {
      title: `Broadcast Details`,
      metaTags: [
        {
          name: 'description',
          content: 'Broadcast details'
        },
        {
          name: 'keywords',
          content: 'Broadcast, WTB, WTS'
        }
      ]
    },
  },
  {
    path: '/parts',
    name: 'parts',
    component: Parts,
    meta: {
      title: `Parts Search`,
      metaTags: [
        {
          name: 'description',
          content: 'Browse for parts offered by our members, or use keyword searches to find what you need'
        },
        {
          name: 'keywords',
          content: 'Parts, Search, Result, Found, Companies, RFQ, Listing, Advertise'
        }
      ]
    },
  },
  {
    path: '/parts/:id',
    name: 'parts',
    component: PartsDetail,
    meta: {
      title: `Part`,
      metaTags: [
        {
          name: 'description',
          content: 'Details for an item as searched'
        },
        {
          name: 'keywords',
          content: 'Parts, Details, Searched, RFQ, Company, Companies, Listing'
        }
      ]
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    meta: {
      title: `Companies`,
      metaTags: [
        {
          name: 'description',
          content: 'Browse through, use keyword searches, or explore the world map to find our verified members through the Company Search'
        },
        {
          name: 'keywords',
          content: 'TBS, Company, Search, Find, Members, Trusted, Gold, Diamond, World, Europe'
        }
      ]
    },
  },
  {
    path: '/companies/:id',
    name: 'companyDetail',
    component: CompanyDetail,
    meta: {
      title: `Company Details`,
      metaTags: [
        {
          name: 'description',
          content: 'Details for a company as searched'
        },
        {
          name: 'keywords',
          content: 'Details, Company, Search, Find, Members, Trusted, Gold, Diamond, World, Europe'
        }
      ]
    },
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: Inventory,
    meta: {
      title: `My Inventory`,
      metaTags: [
        {
          name: 'description',
          content: 'Your currently listed inventory. Add to, modify or delete your inventory as listed'
        },
        {
          name: 'keywords',
          content: 'Inventory, Items, Parts, Manufacturer, Description, Quantity, Condition, Price'
        }
      ]
    },
  },
  {
    path: '/inventory-hits',
    name: 'inventoryHits',
    component: InventoryHits,
    meta: {
      title: `Inventory hits`,
      metaTags: [
        {
          name: 'description',
          content: 'Check the hits your inventory has received'
        },
        {
          name: 'keywords',
          content: 'Inventory, Items, Parts, Manufacturer, Description, Quantity, Condition, Price, Hits, List, TBS'
        }
      ]
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      title: `My Profile`,
      metaTags: [
        {
          name: 'description',
          content: 'Your profile on www.TheBrokerSite.com. Modify your profile details, upload your profile picture, and more.'
        },
        {
          name: 'keywords',
          content: 'Profile, Company, Gold, Trusted, Diamond, Members, Contact, Persons, Details, Telephone, Email'
        }
      ]
    },
  },
  {
    path: '/invoices',
    name: 'invoice',
    component: Invoices,
    meta: {
      title: `Billing History`,
      metaTags: [
        {
          name: 'description',
          content: 'Your invoice listing. View your invoices, and pay your invoices'
        },
        {
          name: 'keywords',
          content: 'Invoice, Listing, View, Pay, Invoices, History, TBS'
        }
      ]
    },
  },
  {
    path: '/testimonials',
    name: 'testimonials',
    component: TestimonialsPage,
    meta: {
      title: `Testimonials`,
      metaTags: [
        {
          name: 'description',
          content: 'See what our members think of www.TheBrokerSite.com!'
        },
        {
          name: 'keywords',
          content: 'Testimonials, Members, TBS'
        }
      ]
    },
  },
  {
    path: '/register',
    name: 'freeTrial',
    component: FreeTrial,
    meta: {
      title: `Register for a Free Trial`,
      metaTags: [
        {
          name: 'description',
          content: 'Sign up now for a FREE 1-month trial of www.TheBrokerSite.com'
        },
        {
          name: 'keywords',
          content: 'Free, Trial, One, Month, 1, TBS, Membership'
        }
      ]
    },
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    children: [
      {
        path: ':categoryName',
        component: News,
        props: true,
      },
    ],
    meta: {
      title: `News`,
      metaTags: [
        {
          name: 'description',
          content: 'The latest news and updates from The Broker Site'
        },
        {
          name: 'keywords',
          content: 'Fraud, Cheat, Payer, Bad, New, Trusted, Gold, Members, European, Broker, Meeting, Events, News, Updates, TBS'
        }
      ]
    },
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    component: News,
    meta: {
      title: `News Item`,
      metaTags: [
        {
          name: 'description',
          content: 'The Broker Site News'
        },
        {
          name: 'keywords',
          content: 'Broker, Site, News'
        },
        {
          property: 'og:title',
          content: 'The Broker Site News'
        },
        {
          property: 'og:description',
          content: 'The Broker Site News'
        },
        {
          property: 'og:image',
          content: ''
        },
        {
          property: 'og:url',
          content: 'https://www.thebrokersite.com/news'
        },
        {
          property: 'og:type',
          content: 'article'
        },
      ]
    },
  },

  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: `About`,
      metaTags: [
        {
          name: 'description',
          content: 'How it works, The Network, Safe Trading, Trusted, Gold and Diamond Members, all you need to know about www.TheBrokerSite.com'
        },
        {
          name: 'keywords',
          content: 'TBS, Gold, Trusted, Diamond, Network, Safe, Trading, Frauds'
        }
      ]
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: `Contact us`,
      metaTags: [
        {
          name: 'description',
          content: 'Contact us at www.TheBrokerSite.com'
        },
        {
          name: 'keywords',
          content: 'TBS, Contact, name, email, telephone, address, po, box'
        }
      ]
    },
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
    meta: {
      title: `Help and FAQs`,
      metaTags: [
        {
          name: 'description',
          content: 'Learn how to use the different features and functions of TheBrokerSite.com and maximize it for your business!'
        },
        {
          name: 'keywords',
          content: 'TBS, Help, Tutorials, Videos, How, To, RSS, Subscribe, Company, Companies, Inventory, Parts, Search, FAQs'
        }
      ]
    },
  },
  {
    path: '/rss',
    name: 'rss',
    component: RSS,
    meta: {
      title: `RSS}`,
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          name: 'keywords',
          content: ''
        }
      ]
    },
  },

  //Footer
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
    meta: {
      title: `Payment`,
      metaTags: [
        {
          name: 'description',
          content: 'Pay for your TBS Membership per year online via Credit Card or Invoice'
        },
        {
          name: 'keywords',
          content: 'TBS, Pay, Credit, Card, Cash, Invoice, Wire, Transfer, Membership'
        }
      ]
    },
  },
  {
    path: '/contract',
    name: 'contract',
    component: Contract,
    meta: {
      title: `Contract Terms`,
      metaTags: [
        {
          name: 'description',
          content: 'Please make sure you read and agree to the Contract Terms before you register'
        },
        {
          name: 'keywords',
          content: 'Contract, Terms, Read, Agree, Register, TBS'
        }
      ]
    },
  },
  {
    path: '/media-kit',
    name: 'mediakit',
    component: Mediakit,
    meta: {
      title: `Mediakit`,
      metaTags: [
        {
          name: 'description',
          content: 'The TBS Media Kit. Download the Media Kit, and use it to promote The Broker Site'
        },
        {
          name: 'keywords',
          content: 'Media Kit, Download, Promote, The Broker Site, TBS'
        }
      ]
    },
   
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: Newsletter,
    meta: {
      title: `Newsletter`,
      metaTags: [
        {
          name: 'description',
          content: 'Subscribe to our newsletter to get the latest news and updates from The Broker Site'
        },
        {
          name: 'keywords',
          content: 'Subscribe, Newsletter, The Broker Site, TBS'
        }
      ]
    },
   
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      title: `Services`,
      metaTags: [
        {
          name: 'description',
          content: 'TBS Services. Directory of services offered by TBS Members'
        },
        {
          name: 'keywords',
          content: 'TBS, Services, Members, Data, Erasure, Transport, Keyboard, Printing, Packaging'
        }
      ]
    },
  },
  {
    path: '/media-kit/about-tbs',
    name: 'mediakit',
    component: AboutTBS,
    meta: {
      title: `About TBS`,
      metaTags: [
        {
          name: 'description',
          content: 'About The Broker Site. How it works, The Network, Safe Trading, Trusted, Gold and Diamond Members, all you need to know about www.TheBrokerSite.com'
        },
        {
          name: 'keywords',
          content: 'About, The Broker Site, How, works, The, Network, Safe, Trading, Trusted, Gold, Diamond, Members, all, need, know, www.TheBrokerSite.com'
        }
      ]
    },
  },
  {
    path: '/media-kit/about-ebm',
    name: 'mediakit',
    component: AboutEBM,
    meta: {
      title: `About the European Broker Meeting`,
      metaTags: [
        {
          name: 'description',
          content: 'About the European Broker Meeting. The European Broker Meeting is a yearly event that brings together traders from all over the world'
        },
        {
          name: 'keywords',
          content: 'About, the, European, Broker, Meeting, The, European, Broker, Meeting, is, a, yearly, event, that, brings, together, traders, from, all, over, the, world'
        }
      ]
    },
  },
  {
    path: '/media-kit/advertising',
    name: 'mediakit',
    component: Advertising,
    meta: {
      title: `Advertising on The Broker Site`,
      metaTags: [
        {
          name: 'description',
          content: 'Advertising on The Broker Site. Advertise on The Broker Site to reach our audience of traders and brokers'
        },
        {
          name: 'keywords',
          content: 'Advertising, The Broker Site, Advertise, Reach, Audience, Traders, Brokers, TBS'
        }
      ]
    },
  },
   {
    path: '/media-kit/social',
    name: 'mediakit',
    component: Social,
    meta: {
      title: `Social ${TITLE_SUFFIX}`,
      metaTags: [
        {
          name: 'description',
          content: 'Social Media for The Broker Site. Follow us on social media to get the latest news and updates from The Broker Site'
        },
        {
          name: 'keywords',
          content: 'Social Media, The Broker Site, Follow, Latest, News, Updates, TBS'
        }
      ]
    },
  },
   {
    path: '/media-kit/videos',
    name: 'mediakit',
    component: Videos,
    meta: {
      title: `Videos`,
      metaTags: [
        {
          name: 'description',
          content: 'Videos from The Broker Site. Watch our videos to learn more about The Broker Site'
        },
        {
          name: 'keywords',
          content: 'Videos, The Broker Site, Watch, Learn, More, TBS'
        }
      ]
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: {
      title: `Privacy Policy`,
      metaTags: [
        {
          name: 'description',
          content: 'Privacy Policy for www.TheBrokerSite.com. Make sure to read the Privacy Policy before using our website'
        },
        {
          name: 'keywords',
          content: 'TBS, The, Broker, Site, Privacy, Policy, Disclaimer, Read, Use, Website'
        }
      ]
    },
  },

  {
    path: '/broadcast-rules',
    name: 'broadcastRules',
    component: BroadcastRules,
    meta: {
      title: `Broadcast Rules`,
    },
  },
   {
    path: '/broadcast-settings',
    name: 'broadcastSettings',
    component: BroadcastSettings,
    meta: {
      title: `Broadcast Settings`,
    },
  },
  {
    path: '/grading-guidelines',
    name: 'gradingGuidelines',
    component: GradingGuidelines,
    meta: {
      title: `Grading Guidelines`,
    },
  },
  {
    path: '/diamond-members',
    name: 'diamondMembers',
    component: DiamondMembers,
    meta: {
      title: `Diamond members`,
    },
  },
  {
    path: '/gold-members',
    name: 'goldMembers',
    component: GoldMembers,
    meta: {
      title: `Gold members`,
    },
  },
  {
    path: '/trusted-members',
    name: 'trustedMembers',
    component: TrustedMembers,
    meta: {
      title: `Trusted Members`,
    },
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: Favorites,
    meta: {
      title: `Favorites`,
      metaTags: [
        {
          name: 'description',
          content: 'Find and view the details, parts, and broadcasts of your favorite companies'
        },
        {
          name: 'keywords',
          content: 'TBS, The, Broker, Site, Page, Part, Broadcast, Company, Favorites, Favorite, Companies, Parts, Broadcasts'
        },
        {
          name: 'robots',
          content: 'index'
        }
      ]
    },
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PartLanding,
    meta: {
      title: `Page not found`,
      metaTags: [
        {
          name: 'description',
          content: 'Page not found on www.TheBrokerSite.com'
        },
        {
          name: 'keywords',
          content: 'TBS, The, Broker, Site, Page, Part, Broadcast, Company, Not, Found'
        },
        // {
        //   name: 'robots',
        //   content: 'noindex'
        // }
      ]
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ''))
        if (element) { //&& element.scrollIntoView
          element.scrollIntoView({block: 'end', behavior: 'smooth'})
        }
      }, 500)
    }
    return { x: 0, y: 0 }
  },
})

//FROM: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

router.beforeEach((to, from, next) => {
  store.dispatch("getRandomBanners");
  store.dispatch("getNewsUnread");
  store.dispatch("logPage", {path: to.fullPath});
  store.dispatch("getLastInvoiceStatus");

  // Google Analytics send manual pageview on each route-change
  window.gtag('event', 'page_view', {
    page_title: to.meta.title,
    page_location: to.fullPath,
    page_path: to.fullPath,
    send_to: 'G-VJ8LLCMQ7R'
  })

  next();
})


export default router
