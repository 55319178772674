<template>
	<base-layout :withHeaderStructure='true'>
		<template v-slot:header>
			<div class="mt-5 mb-3 md:flex md:items-center md:justify-between">
				<div class="flex-1 min-w-0">
					<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:text-3xl leading-9 sm:truncate">
					Broadcasts
					</h2>
				</div>

				<div class="relative inline-block text-left">
					<div v-if='user && user.isServiceOnly===0' class="mt-4 flex md:mt-0 md:ml-4" >
						<button type="button" class="btn btn-primary" @click="showComposeBroadcast">Compose</button>
						<button type="button" class="ml-3 pr-3 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700" @click="$refs.broadcastList.showMainDropdown = !$refs.broadcastList.showMainDropdown">
							More
							<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
								<path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
							</svg>
						</button>
					</div>

					<div v-if='isLoaded'>
						<div v-if='$refs.broadcastList.showMainDropdown' v-on-clickaway="$refs.broadcastList.closeMainDropdown" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50">
					    <div class="rounded-md bg-white shadow-xs">
					      <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
					      	<a href="javascript:void(0)" @click="showBroadcastSettings" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Broadcast Settings</a>
					        <a href="javascript:void(0)" @click="$refs.broadcastList.showBroadcastRulesModal = true; $refs.broadcastList.showMainDropdown = false" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Broadcast Rules</a>
					        <a href="javascript:void(0)" @click="$refs.broadcastList.showGradingGuidelinesModal = true; $refs.broadcastList.showMainDropdown = false" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Grading Guidelines</a>
					      </div>
					    </div>
					  </div>
					</div>
				</div>

				<ModalPossibleMatches></ModalPossibleMatches>
				
			</div>
		</template>
		<template v-slot:main>
			<BroadcastList ref='broadcastList' ></BroadcastList>
		</template>
	</base-layout>
</template>
 
<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import BaseLayout from '@/components/BaseLayout.vue'
import BroadcastList from '@/components/BroadcastList.vue'
import possibleMatchMixin from '@/components/mixins/possibleMatchMixin.js'
import ModalPossibleMatches from '@/components/ModalPossibleMatches.vue'
import { mixin as clickaway } from 'vue-clickaway';

import eventBus from '../utils/eventBus'

export default {
	name: 'Broadcasts',
	mixins: [possibleMatchMixin, clickaway],
	components: {
    BaseLayout,
    BroadcastList,
		ModalPossibleMatches,
  },
  data: () => ({
  	isLoaded: false,
	showNewMatches: false,
  }),
  async mounted(){
  	this.isLoaded = true;

		// HACK for preview TBS
    	// console.log(this.$route);
		if(this.$route.query.possibleMatchId){
			this.showNewMatches = true
			await this.loadPossibleMatches(this.$route.query.possibleMatchId, true)
		}

		if (!this.user) {
			window.location.href = '/' // if the user is logged out, send them to the homepage. We do this without router to prevent caching
		}

		// On broadcast send -> run possible matches logic
		eventBus.$on("broadcastSend", async ({id}) => {
			await this.loadPossibleMatches(id)
		})
  },
  computed: {
		...mapState(['user']),
		...mapGetters(['broadcastsEnrichedFN', 'broadcastsEnriched']),
  },
  methods: {
  	showBroadcastSettings(){
  		this.$refs.broadcastList.showBroadcastSettings();
  	},
  	showComposeBroadcast(){
  		this.$refs.broadcastList.showComposeBroadcast();
  	},
  },
}
</script>
