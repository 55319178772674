<template>
	<base-layout :doSkipHeader="true">
		<template v-slot:main>

			<div >
        <ModalPossibleMatches></ModalPossibleMatches>
        <div v-if='!user' class="rounded-lg shadow-lg overflow-hidden bg-white px-6 relative tracking-tighter  mt-4">

          <main class="relative">
            <div class="max-w-7xl w-full pt-10 pb-16 py-48 text-left">
              <div class="px-4 sm:px-8 xl:pr-16">
                <h1 class="tracking-tight font-extrabold text-gray-900 text-5xl">
                  <span class="inline">Empowering the Circular IT<br>Industry &nbsp;</span>
                  <div class="inline-flex items-center">
                    <span class="inline text-red-600 xl:inline">Since 1999</span>
                    <img src="/img/3.png" class="w-14 ml-4">
                  </div>
                </h1>
                <ul class="mt-3 text-gray-500 sm:mt-5 md:mt-5 text-xl lg:mx-0 lg:max-w-xl xl:max-w-2xl custom-bullets">
                  <li>The B2B marketplace for buying and selling computers, laptops, servers, mobiles and all other related parts and equipment</li>
                  <li>Broadcast to thousands of members within seconds</li>
                  <li>Browse our members and find thousands of new suppliers and customers</li>
                </ul>
                <div class="mt-10">
                    <div class="float-left">
                    <router-link to='/register' class="border border-transparent font-medium rounded-md text-white bg-red-600 hover:bg-red-700 py-4 text-md md:text-lg px-6 mr-10">
                      Register for Free Trial
                    </router-link>
                    </div>
                    <div class="float-left">
                      <span class="hidden lg:block"><a href='http://eepurl.com/bG65cr' target='_blank' rel='noopener' class="border border-transparent font-medium rounded-md text-white bg-red-600 hover:bg-red-700 py-4 text-lg px-6 ml-10">Subscribe to our Newsletter</a></span>
                      <span class="block md:hidden h-32"><br /><br /><a href='http://eepurl.com/bG65cr' target='_blank' rel='noopener' class="border border-transparent font-medium rounded-md text-white bg-red-600 hover:bg-red-700 py-4 text-lg px-10">Our Newsletter</a></span>
                    </div>
                </div>
              </div>
            </div>
            <div class="w-full hidden lg:block">
              <!-- <div class='hero-big absolute bottom-0 right-0 -mb-0'></div> -->
                <div class='absolute bottom-2 right-4 -mb-0'>
                <div class='mt-6 w-96 bg-white shadow overflow-hidden sm:rounded-lg pt-6 pb-6 px-2 sm:px-6 mb-4 text-left cursor-pointer' @click='navToNews'>
                  <div v-if="latestNews" class="text-lg font-bold leading-5 text-gray-500 mb-2">Latest news<br><br><span class="text-blue-600">{{latestNews.title}}</span></div>
                  <div v-if="imageSrc">
                    <img :src="imageSrc">
                  </div>
                </div>
              </div>
            </div>
          </main>
          <!-- <h1 class='text-3xl text-blue-700 font-semibold'>Europe's Leading Computer Trading Exchange Since 1999</h1>  -->

        </div>

				<BroadcastList ref='broadcastList' :isHome="true"></BroadcastList>

        <div v-if='!user' class='my-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-4 lg:max-w-none'> 
          <div class="rounded-lg shadow-lg overflow-hidden bg-white p-6 relative">
            <div class='hero-1 float-right'></div>
            <h2 class='text-xl font-semibold text-red-700 mb-2 leading-6'>The Network</h2>
            <p class='text-sm text-gray-600 pb-12'>
              With close to 3000<br> verified members, The Broker Site is the European marketplace for IT Traders, Brokers, Resellers and Wholesalers.
              <!-- With more than 2500 verified members, The Broker Site is the European marketplace for IT Traders, Brokers, Resellers and Wholesalers. -->
            </p>
            <router-link to='/register' class='absolute bottom-6 right-6 text-red-700 text-sm hover:text-red-600'>Register now...</router-link>
          </div>

          <div class="rounded-lg shadow-lg overflow-hidden bg-white p-6 relative">
            <div class='hero-2 float-right'></div>
            <h2 class='text-xl font-semibold text-red-700 mb-2 leading-6'>Trade Safe</h2>
            <p class='text-sm text-gray-600 pb-12'>
              The Broker Site has been monitoring the IT Industry since 1999 and maintains a detailed database with information on most IT Traders, 
              Brokers, Resellers and Wholesalers both members and non members.
            </p>
	          <router-link to='/register' class='absolute bottom-6 right-6 text-red-700 text-sm hover:text-red-600'>Register now...</router-link>
          </div>

          <div class="rounded-lg shadow-lg overflow-hidden bg-white p-6 relative">
            <div class='hero-3 float-right'></div>
            <h2 class='text-xl font-semibold text-red-700 mb-2 leading-6'>Top Searches</h2>
            <p class='text-sm text-gray-600 pb-12 mt-4'>
              <router-link 
                :to='item.link' v-for='item in topSearchesExtended' :key='item.label' 
                class='float-left hover:underline font-semibold mr-3' :class='item.clazz'
              >
                {{item.label}}
              </router-link>
            </p>
          </div>

          <div class="rounded-lg shadow-lg overflow-hidden bg-white p-6 relative">
            <div class='hero-4 float-right'></div>
            <h2 class='text-xl font-semibold text-red-700 mb-2 leading-6'>Trusted, Gold &amp; Diamond</h2>
            <p class='text-sm text-gray-600 pb-12'>
              We offer four different member types: Regular, Trusted, Gold, and Diamond. To attain Trusted, Gold, or Diamond status, members must meet specific criteria.
            </p>
            <router-link to='/about' class='absolute bottom-6 right-6 text-red-700 text-sm hover:text-red-600'>More info...</router-link>
          </div>


          <!-- <a href='https://www.europeanbrokermeeting.com/' _target='_blank' class="rounded-lg shadow-lg overflow-hidden bg-white p-6 relative" :style='ebmBanner.style'>
          </a> -->


        </div>
        <div v-if='!user' class='border-b pb-6 -mb-0'>
          <!--
          <div class="md:grid md:grid-cols-2 md:gap-4">
            <div class="col-span-1 mb-4 md:mb-0">
              <h2 class="xl:inline-block text-xl leading-8 font-bold text-gray-900 mb-1 mr-4 ">Free Parts Search</h2>
              <input class='form-input max-w-sm mr-2' v-model='partSearch'  @keyup.enter='toParts'>
              <button @click='toParts' type="button" class="btn btn-primary cursor-pointer">Search</button>
            </div>
            <div class="col-span-1">
              <h2 class="xl:inline-block text-xl leading-8 font-bold text-gray-900 mb-1 mr-4">Free Company Search</h2>
              <input class='form-input max-w-sm mr-2' v-model='companySearch' @keyup.enter='toCompanies'>
              <button @click='toCompanies' type="button" class="btn btn-primary cursor-pointer">Search</button>
            </div>
          </div>
          //-->
        </div>
        <div class='pt-4'>
        </div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import { mapState, mapActions, mapGetters} from 'vuex';
import _ from 'lodash'
import BaseLayout from '@/components/BaseLayout.vue'
import BroadcastList from '@/components/BroadcastList.vue'
import possibleMatchMixin from '@/components/mixins/possibleMatchMixin.js'
import ModalPossibleMatches from '@/components/ModalPossibleMatches.vue'
import axios from 'axios'

export default {
  name: 'Home',
  mixins: [possibleMatchMixin],
  components: {
    BaseLayout,
    BroadcastList,
    ModalPossibleMatches,
  },
  data: () => ({
    partSearch: "",
    companySearch: "",
    topSearches: [],
    latestNews: null,
    imageTag: null,
  }),
  async created(){
    const resp = await this.getTopSearches();
    this.topSearches = resp.data;
  },
  async mounted() {
    this.fetchNewsForHome()
  },
  computed: {
     ...mapState(['user', 'route']),
     ...mapGetters(["bannersEnriched"]),
     ebmBanner(){
       if(!this.bannersEnriched.length) return {}
       return this.bannersEnriched[2] //always nr 2
     },
     topSearchesExtended(){
       const items = _.map(this.topSearches, (item, i) => ({
         label: item.partnumber, 
         link: `/parts?query=${item.partnumber}`,
         clazz: i % 3 === 0 ? 'text-yellow-500' : (i % 3 === 1 ? 'text-red-600' : 'text-red-800')
       }))
       // console.log(items)
       return items;
     }
  },
  methods: {
      ...mapActions(['getTopSearches']),
    toParts(){
      this.$router.push({name: "parts", query: {query: this.partSearch}})
    },
    toCompanies(){
      this.$router.push({name: "companies", query: {query: this.companySearch}})
    },
    navToNews() {
      location.href = '/news'
    },
    async fetchNewsForHome() {
      try {
        const response = await axios.get('/api/news/lastForHome')
        if (response.status === 200) {
          this.latestNews = response.data.payload
          // console.log(this.latestNews)
          this.extractImageTag()
        } else {
          console.error('Error fetching latest news:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error fetching latest news:', error)
      }
    },    
    extractImageTag() {
      const bodyContent = this.latestNews.body;
      const imgSrcMatch = bodyContent.match(/<img[^>]+src="([^">]+)"/);
      const rawSrc = imgSrcMatch ? imgSrcMatch[1] : 'No image found';
      this.imageSrc = rawSrc.startsWith('http') || rawSrc.startsWith('data:') ? rawSrc : 'https://www.thebrokersite.com' + rawSrc;
      // console.log(this.imageSrc);
    },    
  },
}
</script>

<style scoped>

  .hero-big {
    width: 336px;
    height: 283px;
    background-image: url("https://www.thebrokersite.com/images/main_images.png");
  }

  .hero-1 {
    width: 62px;
    height: 88px;
    background-image: url("https://www.thebrokersite.com/images/home_icon_network.png");
    background-size: contain;
  }
  .hero-2 {
    width: 62px;
    height: 88px;
    background-image: url("https://www.thebrokersite.com/images/home_icon_trusted.png");
    background-size: contain;
  }
  .hero-3 {
    width: 62px;
    height: 88px;
    background-image: url("https://www.thebrokersite.com/images/home_icon_search.png");
    background-size: contain;
  }
  .hero-4 {
    width: 62px;
    height: 88px;
    background-image: url("/img/1.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

.custom-bullets {
  list-style-type: disc;
  padding-left: 1.5rem;
}
.custom-bullets li {
  color: #6b7280;
  font-size: 1.25rem;
  margin-top: 0.25rem;
  position: relative;
}
  
</style>
