<template>
	<base-layout>
		<template v-slot:header>
			My Inventory
		</template>
		<template v-slot:main>
			<div v-if='isBootstrapped'>

				<div v-if='user.isServiceOnly===0'>
					<div v-if='!nrInventoryTotal' class='font-bold'>You currently have no line items in our database.</div>
					<div v-else class='font-bold'>You currently have {{nrInventoryTotal}} {{nrInventoryTotal === '1' ? 'line item' : 'line items'}} in our database.</div>
					For your convenience, if your inventory exceeds 20 parts, you can send your inventory file (in any format) to <a href="mailto:inventory@thebrokersite.com?Subject=My Inventory File" class='text-red-600 hover:underline'>inventory@thebrokersite.com</a>
					<br>Please note: Members are only permitted to upload inventory that is physically held in their own stock or warehouse.
					<div v-if='!isAddingNew' class="grid grid-cols-4 md:grid-cols-3 gap-6  mb-4 mt-8 py-8 border-b">
						<div class="col-span-2 md:col-span-1 ml-auto mr-auto"><div class="inv-btn inv-add" title="Add items" @click='addNew'></div></div>
					<div class="col-span-2 md:col-span-1 ml-auto mr-auto"><div class="inv-btn inv-del" title="Delete inventory" @click='delAll'></div></div>
					<div class="col-span-2 md:col-span-1 ml-auto mr-auto"><router-link to="/inventory-hits"><div class="inv-btn inv-hits" title="Show Inventory hits"></div></router-link></div>
					<!-- <div class="col-span-2 md:col-span-1 ml-auto mr-auto"><div class="inv-btn inv-help" title="Learn how to manage your inventory"></div></div> -->
					</div>

					<div v-if='isAddingNew || isUpdatingExisting' class='text-sm border-bpy-6 mt-8'>
						<div class="flex flex-col ">
							<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
								<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
									<table class="min-w-full">
										<thead>
											<tr>
												<th class="table-cell-header pr-1">Manufacturer</th>
												<th class="table-cell-header table-cell-header-narrow">Partnumber</th>
												<th class="table-cell-header table-cell-header-narrow td-desc">Description</th>
												<th class="table-cell-header table-cell-header-narrow">Quantity</th>
												<th @mouseover="showConditionPane = true" @mouseleave="showConditionPane = false" class="table-cell-header table-cell-header-narrow underline px-4 text-red-500 cursor-pointer">Condition</th>
												<th class="table-cell-header pl-1">Price</th>
											</tr>
										</thead>
										<tbody>
											<tr class="bg-white">

												<td class="table-cell pr-1"><input v-model="item.manufacturer" class="form-input w-full" placeholder="Manufacturer"/></td>
												<td class="table-cell table-cell-narrow"><input v-model="item.partNumber" v-skipHyphen class="form-input w-full" placeholder="Part number"/></td>
												<td class="table-cell table-cell-narrow"><input v-model="item.description" class="form-input w-full" placeholder="Description"/></td>
												<td class="table-cell table-cell-narrow"><input type='number' v-model="item.quantity" class="form-input w-full" placeholder=""/></td>
												<td class="table-cell table-cell-narrow">
													<select v-model="item.partCondition" class="form-select w-full">
														<option value="F/S">F/S</option>
														<option value="NOB">NOB</option>
														<option value="NEW">NEW</option>
														<option value="REF">REF</option>
														<option value="USED">USED</option>
														<option value="ASIS">ASIS</option>
														<!-- <option value="EXC">EXC</option>
														<option value="REP">REP</option> -->
													</select>
												</td>
												<td class="table-cell pl-1"><input v-model="item.price" class="form-input w-full" placeholder="Price"/></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
					<span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
						<button :disabled='disableSubmit' @click='addOrUpdate' type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5" :class='submitClass'>
							<span v-if='item.id'>Save</span>
							<span v-else>Add</span>
						</button>
					</span>
					<span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
						<button  @click='cancelAdd' type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
						Cancel
						</button>
					</span>
					</div>


						<div v-if="showConditionPane" class="shadow overflow-hidden sm:rounded-lg border-b border-gray-200 p-6 mt-6 bg-white">
							<p class='font-bold'>
							In order to keep our inventory up to standard, and in alignment with global terminology, please use the list below as reference to the condition of your inventory items:
							</p>

							<ul class='mt-4 text-xs'>
								<li><span class='font-bold'>F/S</span> - Factory Sealed in original manufacturer's box, tape and box are not cut. Product contains warranty.</li>
								<li><span class='font-bold'>NOB</span> - New Open Box is an unused product where original manufacturers packaging seal is broken or cut. Product contains warranty.</li>
								<li><span class='font-bold'>NEW</span> - Unused product with or without original manufacturers packaging, such as bulk, pulls, F/S, or NOB items. Product contains warranty.</li>
								<li><span class='font-bold'>REF</span> - Product that has been cleaned and refurbished by a manufacturer or vendor. Product may contain a mfg or vendor warranty.</li>
								<li><span class='font-bold'>USED</span> - Product that has been tested to work, has no warranty, and has not been refurbished.</li>
								<li><span class='font-bold'>ASIS</span> - Unknown condition, does not contain a warranty.</li>
								<!-- <li><span class='font-bold'>EXC</span> - Product that is available for exchange or contain a returnable core.</li>
								<li><span class='font-bold'>REP</span> - Product is available for depot repair.</li> -->
							</ul>
						</div>
					</div>

					<div v-if='nrInventoryTotal'>
						<form @submit.self.prevent="doSearch" class='mt-8'>
							<div class="grid grid-cols-12 gap-6">
								<div class="col-span-12 sm:col-span-6 lg:col-span-5">
									<label for="pSearch" class="form-label block truncate">Part Number, Description, Keywords</label>
									<input v-model="tempQuery" id="pSearch" class="form-input mt-1 block w-full" placeholder="Search your inventory here"/>
								</div>

								<div class="col-span-12 sm:col-span-12 lg:col-span-2 relative">
									<button  @click="doSearch" type="button" class="btn btn-primary float-right sm:float-left lg:mt-6">Search</button>
								</div>
							</div>
						</form>

						<div class="flex flex-col mt-12">
							<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
								<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
									<table class="min-w-full">
										<thead>
											<tr>
												<th class="table-cell-header">Manufacturer</th>
												<th class="table-cell-header">Description</th>
												<th class="table-cell-header">Partnumber</th>
												<th class="table-cell-header">Quantity</th>
												<th class="table-cell-header">Condition</th>
												<th class="table-cell-header">Price</th>
												<th class="table-cell-header">Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in inventoryEnriched" :key="index" class="bg-white" :class='{"": index % 2}'>
												<td class="table-cell">{{item.manufacturer}}</td>
												<td class="table-cell"><div class="w-32 truncate">{{item.description}}</div></td>
												<td class="table-cell">{{item.partNumber}}</td>
												<td class="table-cell">{{item.quantity}}</td>
												<td class="table-cell">{{item.partCondition}}</td>
												<td class="table-cell">{{item.price}}</td>
												<td class="table-cell pr-0">

													<span class="relative z-0 inline-flex shadow-sm rounded-md">
													<button type="button" @click='edit(item)' class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" >

														<svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
															<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
															</svg>

														Edit
													</button>
													<button type="button" @click='del(item)' class="-ml-px relative inline-flex items-center pl-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
														<svg class="-ml-1 mr-2 h-5 w-5 text-gray-400"  viewBox="0 0 20 20" fill="currentColor">
															<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
															</svg>
													</button>
													</span>

													<!-- <button  class="relative items-center btn btn-muted" @click='edit(item)'>Edit</button> --> <!-- @click.stop='$refs.modalRFQ.openModal(item)' -->
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

					</div>

					<modal v-show="showConfirmDeleteModal" @submit="delConfirm" @cancel="showConfirmDeleteModal = false"
						header="Confirm delete" 
						affirmativeButtonLabel='Yes, Delete'>
						<template v-slot:body>
							<div>Are you sure you want to delete this item from your inventory?</div>
					</template>
					</modal>

					<modal v-show="showConfirmDelAll" @submit="delAllConfirm" @cancel="showConfirmDelAll = false"
						header="Confirm delete inventory" 
						affirmativeButtonLabel='Yes, Delete all'>
						<template v-slot:body>
							<div>Are you sure you want to delete your entire inventory?</div>
					</template>
					</modal>

				</div>
				<div v-else class="text-sm mt-2">
					Sorry, your account does not allow for uploading of inventory.<br />
					Please <router-link to="/contact" class="text-red-600 hover:underline">contact us</router-link> for more information.
					<br /><br /><br /><br /><br /><br />
				</div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState, mapGetters} from 'vuex';
import eventBus from '../utils/eventBus'
import BaseLayout from '@/components/BaseLayout.vue'
import Modal from "@/components/modal"


export default {
  name: 'Inventory',
  data: () => ({
    isBootstrapped: false,
    isLoading: false,
    isAddingNew: false,
    isUpdatingExisting: false,
    disableSubmit: true,
    itemToDel: null,
    tempQuery: "",
    payload: {},
    item: {
    	id: null,
    	manufacturer: null, 
    	description: null, 
    	partNumber: null, 
    	quantity: 1, 
    	partCondition: "F/S",
    	price: null
    },
    showConfirmDeleteModal: false,
    showConfirmDelAll: false,
	showConditionPane: false,
  }),
  components: {
    BaseLayout,
    Modal,
  },
  created(){
  	this.load()
  },
  watch: {
    // Route changes with different query params -> reload
    '$route.params'() {
      this.load();
    },
    item(){
    	this.checkItemValid();
    },
    "item.manufacturer"(){
    	this.checkItemValid();
  	},
  	"item.description"(){
    	this.checkItemValid();
  	},
  	"item.partNumber"(){
    	this.checkItemValid();
  	},
  	"item.price"(){
    	this.checkItemValid();
  	},
  },
  computed: {
  	...mapState(['route', 'user', 'nrInventoryTotal']),
  	...mapGetters(['inventoryEnriched', 'inventoryHitsEnriched']),
  	submitClass(){
  		if(this.disableSubmit){
  			return "border-gray-400 bg-gray-400 cursor-default"
  		} else {
  			return "bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red "
  		}
  	}
  },
	directives: {
    skipHyphen: {
      update(el) {
        el.value = el.value.replace(/-/g,'');
      },
    },
	},
  methods: {
  	...mapActions(["getInventory", 'addPart', 'updatePart', 'deletePart', 'deleteAllParts', 'getInventoryHits']),
  	checkItemValid(){
  		this.disableSubmit = (!this.item.manufacturer || !this.item.description || !this.item.partNumber || this.item.price == undefined);
  	},
  	load(){

  		this.tempQuery = this.route.query.query = this.route.query.query || "";
  		this.payload = { query: this.tempQuery}

  		this.isLoading = true;
  		this.getInventory(this.payload).then(() => {
  			this.isBootstrapped = true; 
        this.isLoading = false;
  		})
  	},
  	doSearch(){

     	this.$router.push({ 
     		name: "inventory", 
     		query:  {
	  			query: this.tempQuery || undefined,
	  		} 
	  	})
    },
    resetItem(){
    	this.item = {
    		id: null,
	    	manufacturer: null, 
	    	description: null, 
	    	partNumber: null, 
	    	quantity: 1, 
	    	partCondition: "F/S",
	    	price: null
	    }
    },
    addNew(){
    	this.resetItem();
    	this.isAddingNew = true
    	this.isUpdatingExisting = false
    },
    edit(item){
	    this.isUpdatingExisting = true;
    	this.isAddingNew = false;
    	this.item = item;
    	window.scrollTo(0, 0)
    },
    cancelAdd(){
    	this.resetItem();
	    this.isAddingNew = false;
	    this.isUpdatingExisting = false;
    },
    delAll(){
    	this.showConfirmDelAll = true;
    },
    delAllConfirm(){
    	return this.deleteAllParts()
    	.then(resp => {
    		if(resp.status !== 200) throw resp;
    		this.showConfirmDelAll = false;

			// Notification message for delete. || Troy 14.02.2025
			eventBus.$emit("createSnackbar", { 
				type: "success", 
				title: "Invetory deleted successfully.",
				timeoutInMS: 10000,
			})


    		this.load();
    	})
    	.catch(err => {
    		eventBus.$emit("createSnackbar", {
   				type: "error", 
   				title: "Parts could not be deleted",
   				body: "Reason: " + err.data.payload.msg,
   				timeoutInMS: 5000,
   			})
    	})
    },
    del(item){
    	this.itemToDel = item;
    	this.showConfirmDeleteModal = true;
    },
    delConfirm(){
    	return this.deletePart(this.itemToDel)
    	.then(resp => {
    		if(resp.status !== 200) throw resp;
    		this.itemToDel = null;
    		this.showConfirmDeleteModal = false;

			// Notification message for delete. || Troy 14.02.2025
			eventBus.$emit("createSnackbar", { 
				type: "success", 
				title: "Item deleted successfully.",
				timeoutInMS: 10000,
			})

    		this.load();
    	})
    	.catch(err => {
    		eventBus.$emit("createSnackbar", {
   				type: "error", 
   				title: "Part could not be deleted",
   				body: "Reason: " + err.data.payload.msg,
   				timeoutInMS: 5000,
   			})
    	})
    },
    addOrUpdate(){
    	const item = this.item;
    	if(_.isString(item.price)){
  			item.price = item.price.replace(',', '.');
  		}
    	Promise.resolve().then(() => {

    		if(!item.id) return this.addPart(item)
    		return this.updatePart(item)
    	})
    	.then(resp => {
    		if(resp.status !== 200 || resp.data.status !== 200) throw resp;
    		this.load();
    		this.resetItem();

			let title;
			let message;

			// Notification message for update or add. || Troy 14.02.2025
    		if(item.id && resp.data.type == "update"){	
				title = "Existing part was updated";
    			message =  "The part you entered already exists in your inventory and the existing part was updated."
    		} else {
				message = "Successfully added to your inventory."
			}

			eventBus.$emit("createSnackbar", {
				type: "success", 
				title: title,
				body: message,
				timeoutInMS: 10000,
			})
    	})
    	.catch(err => {
			const data = err.data;
    		eventBus.$emit("createSnackbar", {
   				type: "error", 
   				title: "Part could not be created or updated",
   				body: "Reason: " + data.payload.msg,
   				timeoutInMS: 5000,
   			})
    	})

    },
  },

}
</script>


<style>
	
	.td-desc{
		width: 350px;
	}
	.inv-btn{
    width: 127px;
    height: 115px;
    background-image: url(../assets/inventory_buttons.png);
    cursor: pointer;
	}
	.inv-del{
		width: 135px;
		background-position: -130px 0px;
	}
	.inv-hits{
		background-position: -267px 0px;
	}
	.inv-help{
		background-position: -399px 0px;
	}

    .info-icon {
      display: inline-block;
      font-size: 8px; /* Adjust the size as needed */
      font-weight: bold;
      color: #3498db; /* Color of the icon (blue in this case) */
      border-radius: 50%;
      width: 15px;
      height: 15px;
      text-align: center;
      line-height: 15px;
      border: 2px solid #5c5c5c; /* Border around the icon */
      cursor: pointer;
      font-family: Arial, sans-serif; /* Optional: to ensure the correct font */
    }

    .info-icon:hover {
      background-color: #3498db;
      color: white;
    }
</style>
