<template>
  <base-layout>
    <template v-slot:header>
      Services
    </template>
    <template v-slot:main>
      <div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3">
          <div v-for="(serviceCompanies, index) in companyByServiceCat" :key="index" class="mt-4 pb-4 cursor-pointer"
               @click='toggleCategory(serviceCompanies[0].catId)' :id="serviceCompanies[0].catName">
            <div class="relative">
              <div class="relative max-w-screen-xl mx-auto px-2">
                <div class="w-full mx-auto rounded-lg shadow-lg overflow-hidden bg-white">
                  <div class="float-left bg-white px-4 py-4 lg:p-8 w-40 sm:w-96">
                    <h3 class="relative text-2xl leading-8 font-bold text-gray-900">
                      {{ serviceCompanies[0].catName }}
                      <div class='absolute tag ml-2 bg-red-600 text-white text-s px-1.5 py-0.5 leading-tight'>
                        {{ serviceCompanies.length }}
                      </div>
                    </h3>
                  </div>
                  <div class="float-right bg-white px-1 py-1 lg:p-2">
                    <img class="object-fill w-4/5" :src="serviceCompanies[0].imgName"
                         :title="serviceCompanies[0].catName">
                  </div>
                  <Transition name="fade">
                    <div v-show="expandedCatId === serviceCompanies[0].catId" class="clear-both p-4 pt-0">
                      <div class="w-full border-t-2 border-gray-200 mb-2"></div>
                      <div v-for="serviceCompany in serviceCompanies" :key="serviceCompany.id" class="p-1 px-1 lg:px-4">
                        <div class="clear-left w-80 float-left h-9 mb-1 truncate"
                             @click.stop="fetchCompanyAndDisplayModal(serviceCompany.id, 1)">
                          {{ serviceCompany.name }}
                        </div>
                        <div class="float-right w-32">
                          <div class="float-left">
                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor"
                                 viewBox="0 0 20 20">
                              <path fill-rule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clip-rule="evenodd"/>
                            </svg>
                          </div>
                          <div class="float-left">
                            {{ processCountryName(serviceCompany.compCountry) }}
                          </div>
                        </div>
                      </div>
                      <div class="clear-both w-full border-t-2 border-gray-200 mt-8 mb-6 pt-6 text-center text-sm">
                        To be listed in this category, please contact
                        <a :href="'mailto:rhody@thebrokersite.com?Subject=TBS Service listing in category ' + serviceCompanies[0].catName"
                           class="text-red-500">
                          rhody@thebrokersite.com
                        </a>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clear-both"></div>
        <modal v-show="showCompanyModal" @submit="closeCompanyModal" @cancel="closeCompanyModal"
               :doSkipHeader="true"
               :doSkipCancel="true"
               customCssClasses="sm:max-w-6xl"
               affirmativeButtonLabel='Close'>
          <template v-slot:body>
            <CompanyBody
                ref="companyBody"
                :isInModal="true"
                :selectedCompanyEnriched='selectedCompany'
                :contactsEnriched="selectedCompanyContacts"
                @openModal="openContactModal"
            />
          </template>
        </modal>

        <modal-contact ref='contactModal' action='sendMailToContact'></modal-contact>
      </div>
    </template>
  </base-layout>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { mapActions, mapState, mapMutations } from 'vuex';
import BaseLayout from '@/components/BaseLayout.vue'
import Modal from '@/components/modal.vue'
import CompanyBody from '@/components/CompanyBody.vue'
import ModalContact from "@/components/ModalContact.vue";

export default {
  name: 'Services',
  components: {
    ModalContact,
    BaseLayout,
    Modal,
    CompanyBody,
  },
  data: () => ({
    serviceCompanies: null,
    selectedCompanyContacts: [],
    selectedCompany: {},
    showCompanyModal: false,
  }),
  computed: {
    ...mapState(['route', 'user', 'expandedCatId', 'categories']),
    companyByServiceCat() {
      const serviceCompaniesExtended = _.map(this.serviceCompanies, serviceCompany => {
        return _.extend(serviceCompany, {href: `/companies/${serviceCompany.id}?iS=1`})
      })
      return _.groupBy(serviceCompaniesExtended, "catName")
    },
  },
  watch: {
    '$route.hash': 'onRouteHashChange'
  },
  mounted() {
    this.onRouteHashChange();
  },
  async created() {
    this.serviceCompanies = (await axios.get(`/api/services`)).data.payload
  },
  methods: {
    ...mapActions(["getCompany"]),
    ...mapMutations(['setExpandedCategory']),
    openContactModal(contact) {
      this.$refs.contactModal.openSendMessageModal(contact);
    },
    toggleCategory(catId) {
      this.setExpandedCategory(this.expandedCatId === catId ? null : catId);
    },
    isCategoryExpanded(catId) {
      return this.expandedCatId === catId;
    },
    onRouteHashChange() {
      const categoryName = decodeURIComponent(this.$route.hash.replace('#', '')).replace(/_/g, " ");
      const category = this.categories.find(cat => cat.name.toLowerCase() === categoryName.toLowerCase());
      console.log("Category name: ", categoryName)
      console.log("Cat: ", category?.id)
      if (category) {
        this.setExpandedCategory(category.id);
        this.$nextTick(() => {
          const element = document.getElementById(categoryName);
          if (element) {
            element.scrollIntoView({behavior: 'smooth'});
          }
        });
      }
    },
    fetchCompanyAndDisplayModal(id) {
      this.getCompany(id).then(resp => {
        if (resp.status !== 200) throw new Error("TODO: not found. show error page")
        this.selectedCompany = resp.company;
        this.selectedCompanyContacts = resp.contacts;
        this.showCompanyModal = true;
      })
    },
    closeCompanyModal() {
      this.showCompanyModal = false;
    },
    navToCompany(item, skipIfNotLoggedIn) {
      if (skipIfNotLoggedIn && !this.user) return;
      if (this.isPossibleMatches) return window.open(this.$router.resolve({path: `/companies/${item.companyId}`}).href, '_blank');
      this.fetchCompanyAndDisplayModal(item.companyId);
    },
    processCountryName(strCountry) {
      switch (strCountry) {
        case "United Arab Emirates":
          return "UAE";
        case "United States":
          return "USA";
        case "United Kingdom":
          return "UK";
        default:
          return strCountry;
      }
    },
  },
}
</script>
<style scoped>
.fade-enter-active {
  transition: opacity .5s;
}

.fade-leave-active {
  transition: opacity .0s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
