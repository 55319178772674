/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'; // ajax library
import moment from 'moment';	
import _ from "lodash";
import axiosDefaults from 'axios/lib/defaults';
import { uuid } from 'uuidv4';

import storeUtils from './utils'

Vue.use(Vuex)

function dateHuman(dtString){
  const dt = new Date(dtString);
  return `
    ${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth()+1).toString().padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}
  `
}

let lastNewsUpdate = 0
export default new Vuex.Store({
	getters: {
    isFirstLoad: () => { //used for setting cookie message on first load
      const isFirstLoad = !localStorage.getItem('isLoadedBefore')
      localStorage.setItem('isLoadedBefore', true)
      return isFirstLoad
    },
		broadcastsEnriched: (state, getters) => {
      return getters.broadcastEnrichedFN(state.broadcasts)
		},
    broadcastEnrichedFN: state => broadcasts => {
      return _.map(broadcasts, (item, i) => {
        return _.extend({
          index: i,
          dateAsString: moment(item.date).format('D MMM YYYY'),
          dateAsStringLong: moment(item.date).format('D MMM YYYY HH:mm:ss'),
          broadcastListName: state.broadcastMap[item.broadcastList]?.shortNameForList || state.broadcastMap[item.broadcastList]?.name,
          isOwn: state.user && +item.companyId === +state.user.companyId,
          isOkToResend: Date.now() - new Date(item.date).getTime() > (3 * 24 * 60 * 60 * 1000), // 3 days
        }, item)
      })
    },
    partsEnriched: state => { //NOTE: this is an array of objects. One for each query. This allows multiple part queries at the same time
      
      let conversion = 1;
      if(state.currency === "USD"){
        conversion = state.currencyMap.USD;
      }else if(state.currency === "GBP"){
        conversion = state.currencyMap.GBP;
      }

      const arr = [];
      _.each(state.parts, catOrig => {
        const cat = _.cloneDeep(catOrig);
        cat.results = _.map(cat.results, item => _.extend({
          priceLocalCurrency: item.price === "CALL" ? "CALL" : Math.round(item.price * conversion),
        }, item))
        arr.push(cat)
      })

      return arr;
    },
    companiesEnriched: state => {
      return _.map(state.companies, item => _.extend({
        // Nothing yet
      }, item))
    },

    servicesEnriched: state => {
      return _.map(state.services, item => _.extend({
        // Nothing yet
      }, item))
    },

    newsEnriched: state => {
      return _.map(state.news, item => {
        let bodyStart;
        let doReadMore = false;

        // First, check if we have an img tag with data URL at the start
        const imgMatch = item.body.match(/<img[^>]+src="(data:image[^"]+)"/);
        
        if (imgMatch) {
          // console.log('Found data URL img tag');
          // Get the complete img tag
          const fullImgTag = imgMatch[0] + '>';
          // console.log('Full img tag:', fullImgTag);
          
          // Find where the img tag ends in the original content
          const imgTagEnd = item.body.indexOf('>', imgMatch.index) + 1;
          
          // Take content after the img tag up to a reasonable limit
          const afterImgContent = item.body.substring(imgTagEnd, imgTagEnd + 350);
          
          // Combine the full img tag with truncated content after it
          bodyStart = fullImgTag + afterImgContent + ' ...';
          doReadMore = true;
        } else {
          // Original truncation logic for non-data URL content
          if(item.body.length > 350){
            const nextEnter = item.body.indexOf(`\n`, 350);
            if(~nextEnter && nextEnter < 350){
              bodyStart = item.body.substring(0, nextEnter - 1);
              bodyStart += ' ...'
              doReadMore = true;
            } else {
              bodyStart = item.body.substring(0,350);
              bodyStart += ' ...'
              doReadMore = true;
            }
          }
        }

        if(!bodyStart){
          bodyStart = item.body;
        }

        return _.extend({
          catName: state.categoryMap["" + item.catId],
          bodyHtml: bodyStart
            .replace(/(?:\r\n|\r|\n)/g, '<br>') // actual tags
            .replace(/images\//g, '../images/'), // rel paths for images
          doReadMore,
        }, item)
      })
    },

    newsPostEnriched: state => {
      if(!state.newsPost) return state.newsPost;
      return _.extend({
        catName: state.categoryMap["" + state.newsPost.catId],
        bodyHtml: state.newsPost.body
          .replace(/(?:\r\n|\r|\n)/g, '<br>') // actual tags
          .replace(/images\//g, '../../images/'), // rel paths for images
      }, state.newsPost)
    },

    countriesOptions: state => {
      const countryOptions = _.map(state.countries, countryName => ({value: countryName, name: countryName}));
      return [{value: "", name: "Any Country"}].concat(countryOptions)
    },

    countriesOptionsPleaseSelect: state => {
      const countryOptions = _.map(state.countriesAll, countryName => ({value: countryName, name: countryName}));
      return [{value: "", name: "Please Select Country"}].concat(countryOptions)
    },

    countriesReverseCharge: () => {
      return [
        "Austria",
        "Belgium",
        "Bulgaria",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Estonia",
        "Finland",
        "France",
        "German Democr. Rep.",
        "Germany, Fed. Rep. Of",
        "Greece",
        "Hungary",
        "Ireland",
        "Italy",
        "Latvia",
        "Lithuania",
        "Luxembourg",
        "Malta",
        "Poland",
        "Portugal",
        "Romania",
        "Slovakia",
        "Slovenia",
        "Spain",
        "Sweden",
      ]
    },

    markersEnriched: state => {
      return _.map(state.markers, item => _.extend({
        position: {
          lat: item.lat, 
          lng: item.lng,
        },
        infoText: `<div><span class='font-semibold'>${item.name}</span><br /><a class="text-red-700 mt-2 hover:underline inline-block" href="/companies/${item.id}" target="_blank" rel="noopener">More info...</a></div>`
      }, item))
    },

    savedSearches: state => {
      return  _.map(state.savedSearches, (ss, i) => _.extend({
        index: i,
        created: moment(ss.createdDT).format('YYYY-MM-DD'),
        lastHit: ss.lastHitDT ? moment(ss.lastHitDT).fromNow() : '-',
        doUpdateImmediately: !ss.isDigest,
        search: _.compact([ss.q? `Query: ${ss.q}`: null, ss.broadcastType, ss.listType ? `List: ${state.broadcastMap[ss.listType]?.name}` : `All lists`]).join(" - ")
      }, ss)); // No enrichment here (yet)
    },

    companyNameMap: state => {
      return _.reduce(state.markers, (agg, item) => {
        agg[item.id] = item.name
        return agg;
      },{})
    },
    bannersEnriched: state => {
      return state.banners;
    },
    inventoryEnriched: state => {
      return _.map(state.inventory, inv => _.extend({}, inv, {price: inv.price ? inv.price : "CALL" }))
    },
    inventoryHitsEnriched: state => {
      return _.map(state.inventoryHits, hit => {
        hit.dateHuman = dateHuman(hit.logdate)
        if(!hit.username) return _.extend(hit, {name: "Guest"});
        if(!hit.companyname) return _.extend(hit, {name: "Former TBS Member"});
        return _.extend(hit, {name: hit.companyname});
      })
    },
    userPicCSS: state => {
      return {
        background: `url('${state.profilePic}') no-repeat right center / cover`,
      }
    }
	},
  state: {
    expandedCatId: '',
    categories: [
      { id: 1, name: 'Data Erasure Software' },
      { id: 2, name: 'Keyboard Re-printing' },
      { id: 3, name: 'Packaging' },
      { id: 4, name: 'Transport' },
      { id: 7, name: 'Auditing Software' },
      { id: 8, name: 'Licensing' },
      { id: 9, name: 'B2C Platforms' },
      { id: 10, name: 'Hardware Refurbishment' },
      { id: 11, name: 'ERP Solutions' },
      { id: 12, name: 'IT Asset Disposition' },
      { id: 13, name: 'Skins Production & Sales' },
      { id: 14, name: 'Mobile Diagnostics' },
      { id: 15, name: 'WEEE Recycling' },
      { id: 16, name: 'Media' },
    ],
    hideCookiesPopup: false,
    pageSize: localStorage.getItem('pageSize') ? +localStorage.getItem('pageSize') : 10,
    currencyMap: {
      USD: 1,
      GBP: 1,
    },
    currency: "EUR",
    categoryMap: {
      "8": "Bad Debtors",
      "2": "European Broker Meeting",
      "1": "Fraud warnings",
      "3": "Members",
      "4": "The Broker Site",
      "9": "Blog",
    },
    settingsMap: {},
    nrBroadcasts: 0,
    nrParts: 0,
    nrCompanies: 0,
    nrTraders: 0, 
    nrCountries: 0,
    nrInventoryTotal: 0, 
    nrInventoryHitsTotal: 0, 
    nrNewsItems: 0, 
    numFreshNews: 0, 
    
		broadcasts: [],
    parts: [], 
    companies: [], 
    countries: [], //loaded once
    countriesAll: [], //for register / freeTrial
    markers: [], //loaded once
    testimonials: [],  //TBS testimonials
    banners: [], 
    inventory: [],
    inventoryHits: [], 
    news: [], 
    newsPost: {body: ''}, 

    savedSearches: [],
    user: null,
    profilePic: null, 

    lastSearch: {
      companies: {}, 
      parts: {}, 
      broadcasts: {},
    },

    invoiceStatus: {},
    invoices: [],
    creditNotes: [],
    invoicesEBM: [],
    contactStatus: {}, //contains 'freeTrialBlock' / 'Free Trial Footer' / 'modalMessage' / 'contact_name'
    isModalMessageDismissed: false, 
    isInvoiceOverdueMessageDismissed: false,
    
    doUnapprovedRecsExist: false, 

    // isLoggedIn: true, //TODO: deprecate in favor of user
    broadcastMap:  {
      "1": {
        name: "PC",
        shortName: "pc", 
        order: 1, 
        email: "pc@broker-broadcast.com"
      },  
      "2": {
        name: "Server/Storage",
        shortName: "server", 
        shortNameForList: "Server",
        order: 4,
        email: "server@broker-broadcast.com"
      },  
      "4": {
        name: "Networking/Telecom",
        shortName: "networking", 
        shortNameForList: "Networking", 
        order: 5,
        email: "networking@broker-broadcast.com"
      },  
      "5": {
        name: "Printer",
        shortName: "printer", 
        order: 8,
        email: "printer@broker-broadcast.com"
      },  
      "6": {
        name: "Software",
        shortName: "software", 
        order: 12,
        email: "software@broker-broadcast.com"
      },  
      "7": {
        name: "Laptop",
        shortName: "laptop",
        order: 2, 
        email: "laptop@broker-broadcast.com"
      },  
      "8": {
        name: "Electronics",
        shortName: "electronics", 
        order: 9, 
        email: "electronics@broker-broadcast.com"
      },  
      "10": {
        name: "POS",
        shortName: "pos", 
        order: 11,
        email: "pos@broker-broadcast.com"
      },  
      "11": {
        name: "Mobile",
        shortName: "mobile", 
        order: 6,
        email: "mobile@broker-broadcast.com"
      },  
      "12": {
        name: "Components",
        shortName: "components", 
        order: 3,
        email: "components@broker-broadcast.com"
      },  
      "13": {
        name: "Monitor",
        shortName: "monitor", 
        order: 10,
        email: "monitor@broker-broadcast.com"
      },  
      "14": {
        name: "Tablet",
        shortName: "Tablet", 
        order: 7,
        email: "tablet@broker-broadcast.com"
      },  
    }

  },
  mutations: {
    setExpandedCategory(state, categoryId) {
      state.expandedCatId = categoryId;
    },
    doHideCookiesPopup(state){
      state.hideCookiesPopup = true
    },
    updateSettings(state, settingsMap){
      state.currencyMap = {
        USD: +settingsMap.USD,
        GBP: +settingsMap.GBP,
      };
      state.settingsMap = settingsMap;
    },
    changeCurrency(state, newCurrency){
      state.currency = newCurrency;
    },
    updateBanners(state, banners){
      state.banners = banners;
    },
  	updateBroadcasts (state, {results, numResults}) {
  		state.broadcasts = results;
      state.nrBroadcasts = numResults
  	},
    updateParts (state, results) {
      state.parts = results;
      if(results.length){
        state.nrParts = results[0].numResults
      }
    },
    setModalMessageDismissed(state){
      state.isModalMessageDismissed = true;
    },
    setInvoiceOverdueMessageDismissed(state){
      state.isInvoiceOverdueMessageDismissed = true;
    },
    updateInventory(state, {results, numTotalResults}) {
      state.inventory = results;
      state.nrInventoryTotal = numTotalResults
    },

    updateInventoryHits(state, {results, numTotalResults}) {
      state.inventoryHits = results;
      state.nrInventoryHitsTotal = numTotalResults
    },

    updateCompanies (state, {results, numResults, numTraders, numCountries}) {
      state.companies = results;
      state.nrCompanies = numResults
      state.nrTraders = numTraders
      state.nrCountries = numCountries
    },
    updateNews(state, {results, numResults}){
      state.news = results
      state.nrNewsItems = numResults;
    },
    updateNewsPost(state, result){
      state.newsPost = result
    },
    updateCountries (state, {results}) {
      state.countries = results;
    },
    updateCountriesAll (state, results) {
      state.countriesAll = results;
    },
    updateMarkers (state, {results}) {
      state.markers = results;
    },
    updateSavedSearches (state, {results}) {
      state.savedSearches = results;
    },
    updateTestimonials (state, payload) {
      state.testimonials = payload;
    },
    bootstrapUserFromLocalStorage(state){

      const userStr = localStorage.getItem('user');
      if(!userStr) return;
      
      const user = JSON.parse(userStr)
      
      state.user = user;
      axiosDefaults.headers.Authorization = `Bearer ${user.token}`
    },

    // Called on app startup + login
    updateUser(state, user){

      if(user.doLogout) return this.dispatch("signout")

      // const trustedSig = user.isGold ? "(Gold Member)" : (user.isTrusted ? "(Trusted Member)" : ""); // we have to replace this to include the new Diamond Member Status
			const trustedSig = user.isDiamond ? "(Diamond Member)" : (user.isGold ? "(Gold Member)" : (user.isTrusted ? "(Trusted Member)" : ""));
      user.signature = `\n\n${user.name}, ${user.function}\n${user.companyName} ${trustedSig}\n${user.website}\n${user.address}, ${user.country}\nTelephone: ${user.tel} \n\n\n`;
      localStorage.setItem('user', JSON.stringify(user))
      state.user = user;
      axiosDefaults.headers.Authorization = `Bearer ${user.token}`

      if(Date.now() > lastNewsUpdate + 1000){
        lastNewsUpdate = Date.now()
        this.dispatch("getNewsUnread"); //initial. Needed here because only now the Auhth header is set
      }
    },

    removeUser(state){
      localStorage.removeItem('user')
      state.user = null;
      delete axiosDefaults.headers.Authorization
    },
    updateProfilePic(state, profilePicBase64){
      localStorage.setItem('profilePic', profilePicBase64)
      state.profilePic = profilePicBase64;
    },
    removeProfilePic(state){
      localStorage.removeItem('profilePic')
      state.profilePic = null;
    },

    updateNewsUnread(state, newsUnread){
      state.numFreshNews = newsUnread;
    },

    updateLastInvoiceStatus(state, status){
      if(!status) return; // unauth
      state.contactStatus = _.omit(status, ['invoiceDTO'])
      state.invoiceStatus = status.invoiceDTO || {}
    },

    updateInvoices(state, invoices){
      state.invoices = invoices
    },
    updateCreditNotes(state, creditNotes){
      state.creditNotes = creditNotes
    },
    updateInvoicesEBM(state, invoices){
      state.invoicesEBM = invoices
    },
  },
  actions: {
    getTopSearches({ commit }, payload = {}) {
      return axios.get(`/api/misc/topsearches`).then(({ data }) => ({
        data: data.payload,
        status: data.status
      }));
    },
    getSettings({ commit }, payload = {}) {
      return axios.get(`/api/misc/settings`).then(({ data }) => {
        commit('updateSettings', data.payload)
      });
    },
    getNewsUnread({ state, commit }) {
      if(!axiosDefaults.headers.Authorization) return;
      return axios.get(`/api/inventory/unreadNews`).then(({ data }) => {
        commit('updateNewsUnread', data.payload)
      });
    },
    getRandomBanners({ commit }) {
      return axios.get(`/api/misc/randomBanners`).then(({ data }) => {
        commit('updateBanners', data.payload)
      });
    },
    incrementClickForBanner({commit}, id){
      axios.get(`/api/misc/randomBanners/${id}/incrementNumClicked`)
    },
  	getBroadcasts({ state, commit }, payload = {}) {
      state.lastSearch.broadcasts = payload

	    return axios.get(`/api/broadcasts`, { params: payload }).then(({ data }) => {
      	commit('updateBroadcasts', data.payload)
      });
	  },

    getBroadcast({ commit, state }, id) {

      // getCompany is called in pre-hook, so we need to make sure user is bootstrapped. (This is done in app.created, which is called later)
      commit('bootstrapUserFromLocalStorage')
      
      return axios.get(`/api/broadcasts/${id}`).then(({ data }) => {
        if(data.status !== 200) return { status: data.status, }

        // // let's split the body in two parts, the header with the broadcast fields, and the rest with the body and footer.
        // let msgElems = data.result.message.split(/\r?\n{2,}(.*)/s) // we split by double new line, but only once right after the header, to prevent the rest of the body from being split by double lines
        // let msgHeader = msgElems[0].replace(/(?:\r\n|\r|\n)/g, '<br>')
        // if (!msgElems[1]) msgElems[1] = ''
        // let msgRestBody = msgElems[1].replace(/(?:\r\n|\r|\n)/g, '<br>')
        
        // let urlRegex = /(\b(www\.?)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        // let urlRegexProdLink = /(?![^<]*>|[^<>]*<\/)((https?):\/\/[a-z0-9&#=./\-?_]+)/gi;
        // // msgHeader = msgHeader.replace(/(http?|https?):\/\/www./ig, 'www.')
        // msgHeader = msgHeader.replace(urlRegexProdLink, "<a href='$1?utm_source=thebrokersite&utm_medium=broadcast&utm_campaign=TBS-Membership' target=\"_blank\" rel=\"noopener\" class=\"text-red-700\">$1</a>")
        // msgRestBody = msgRestBody.replace(/(http?|https?):\/\/www./ig, 'www.')
        // msgRestBody = msgRestBody.replace(urlRegex, "<a href='https://$1' target=\"_blank\" rel=\"noopener\" class=\"text-red-700\">$1</a>")

// Split email into header and body
let msgElems = data.result.message.split(/\r?\n{2,}(.*)/s); // Splits only once after header
let msgHeader = msgElems[0].replace(/(?:\r\n|\r|\n)/g, '<br>');
if (!msgElems[1]) msgElems[1] = '';
let msgRestBody = msgElems[1].replace(/(?:\r\n|\r|\n)/g, '<br>');

// Function to replace plain text URLs but ignore those already inside <a> tags
function convertPlainUrlsToLinks(text) {
    // First, temporarily replace existing links with a placeholder
    const links = [];
    const textWithoutLinks = text.replace(/<a\b[^>]*>.*?<\/a>/gi, match => {
        links.push(match);
        return `##LINK${links.length - 1}##`;
    });

    // Convert plain URLs to links
    const processedText = textWithoutLinks.replace(
        /(\b(https?:\/\/|www\.)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
        (match, url) => {
            let href = url.startsWith("http") ? url : `https://${url}`;
            return `<a href="${href}" target="_blank" rel="noopener" class="text-red-700">${url}</a>`;
        }
    );

    // Restore original links
    return processedText.replace(/##LINK(\d+)##/g, (match, index) => links[index]);
}

// Process Header (Preserve existing links and convert plain URLs)
msgHeader = convertPlainUrlsToLinks(msgHeader);

// Process Body (Convert only plain text URLs)
msgRestBody = convertPlainUrlsToLinks(msgRestBody);

// Then add <br> before each <p> tag
msgRestBody = msgRestBody.replace(/<p/g, '<br><p');

        let msgAutoLinked = msgHeader + "<br><br>" + msgRestBody

        return {
          status: 200, 
          broadcast: _.defaults(data.result, {
            broadcastListName: state.broadcastMap[data.result.listId]?.name,
            messageFormatted: msgAutoLinked
          })
        };
      });
    },
    getBroadcastPossibleMatchesOld({ state, commit }, {broadcastId, strategy = "stripAndFuzzy", showOutputStrategy, isFromOwnBroadcasts = false}) {
      let urlOld = `/api/broadcasts/possibleMatches/${broadcastId}/${strategy}`
      if(showOutputStrategy){
        urlOld += '?showStrategy'
      }
      if(isFromOwnBroadcasts){
        urlOld += `${showOutputStrategy ? '&' : '?'}isFromOwnBroadcasts=true`
      }
	    return axios.get(urlOld).then(({ data }) => data);
	  },    
    async getBroadcastPossibleMatches({ state, commit }, {broadcastId, queryListId}) {
        const url = `/api/broadcasts/matchedBroadcastIds/${broadcastId}`
        try {
          const { data: matchedBroadcastIdsFetch } = await axios.get(url);
          const broadcastDetails = [];
          for (const broadcastMatch of matchedBroadcastIdsFetch.payload.results || []) {
            const id = broadcastMatch.broadcastId;
            const matchPercentage = broadcastMatch.similarity.toFixed(2) * 100
            const detailUrl = `/api/broadcasts/${id}`;
            const response = await axios.get(detailUrl);
            if (response.status === 200) {
              const broadcast = response.data.result;
              if (!(broadcast.listId === queryListId)) continue;
              broadcastDetails.push({
                "id": broadcast.id,
                "broadcastList": broadcast.listId,
                "companyId": broadcast.companyId,
                "companyName": broadcast.company.name,
                "subject": `${matchPercentage}% ${broadcast.subject}`,
                "date": broadcast.date,
                "isTrusted": broadcast.company.isTrusted,
                "isGold": broadcast.company.isGold,
                "isDiamond": broadcast.company.isDiamond
              });
            }
          }
          return { status: 200, payload: { results: broadcastDetails } };
        } catch (error) {
          console.error('Error fetching broadcast matches:', error);
          return { status: 500, payload: { results: [] } };
        }
	  },
    getParts({ state, commit }, payload = {}) {
      state.lastSearch.parts = payload
      return axios.get(`/api/parts`, { params: payload }).then(({ data }) => {
        commit('updateParts', data.payload)
      });
    },
    getPart({ commit }, id) {

      // getCompany is called in pre-hook, so we need to make sure user is bootstrapped. (This is done in app.created, which is called later)
      commit('bootstrapUserFromLocalStorage')

      return axios.get(`/api/parts/${id}`).then(({ data }) => {
        if(data.status !== 200) return { status: data.status }
        return {
          status: 200, 
          part: _.defaults(data.result, {
            contactsCombined: _.map(data.result.contacts, "name").join(", "),
          })
        };
      });
    },
    getPartStat( { commit }, partNumber ) {
      // this is where we check if a part is in the part landing table. If not, we return part = null to trigger a page not found
      return axios.get(`/api/parts/landing/${partNumber}`).then(({ data }) => {
        if (data.status !== 200) return { part: null }
        return {
          status: 200,
          result: data.result
        }
      })
    },

    getInventory({ commit }, { query}) {
      return axios.get(`/api/inventory?query=${query}`).then(({ data }) => {
        commit('updateInventory', data.payload)
      });
    },

    getInventoryHits({ commit }, {page}) {
      return axios.get(`/api/inventory/hits?page=${page}`).then(({ data }) => {
        commit('updateInventoryHits', data.payload)
      });
    },

    getCompanies({ state, commit }, payload = {}) {
      state.lastSearch.companies = payload
      return axios.get(`/api/companies`, { params: payload }).then(({ data }) => {
        commit('updateCompanies', data.payload)
      });
    },
    getNews({ commit }, payload = {}) {
      return axios.get(`/api/news`, { params: payload }).then(({ data }) => {
        commit('updateNews', data.payload)
      });
    },
    getNewsPost({ commit }, id) {
      return axios.get(`/api/news/${id}`).then(({ data }) => {
        commit('updateNewsPost', data.result)
      });
    },
    getLatestPosts({}, country = null){
      return axios.get(`/api/news/latest`).then(({ data }) => data);
    },
    getCompany({ commit, dispatch }, id) {
      
      // getCompany is called in pre-hook, so we need to make sure user is bootstrapped. (This is done in app.created, which is called later)
      commit('bootstrapUserFromLocalStorage')
      
      return axios.get(`/api/companies/${id}`).then(({ data }) => {
        if(data.status !== 200) return { status: data.status }
        return storeUtils.enrichProfile(data)
      });
    },
    getProfile({ commit, dispatch }) {
      
      // getCompany is called in pre-hook, so we need to make sure user is bootstrapped. (This is done in app.created, which is called later)
      commit('bootstrapUserFromLocalStorage')
      
      return axios.get(`/api/companies/me`).then(({ data }) => {
        if(data.status !== 200) return { status: data.status }
        return storeUtils.enrichProfile(data)
      });
    },
    getCountries({ commit }, payload = {}) {
      return axios.get(`/api/companies/countries`).then(({ data }) => {
        commit('updateCountries', data.payload)
      });
    },
    getCountriesAll({ commit }, payload = {}) {
      return axios.get(`/api/misc/countriesAll`).then(({ data }) => {
        commit('updateCountriesAll', data.payload)
      });
    },

    getMarkers({ commit }, payload = {}) {
      return axios.get(`/api/companies/markers`).then(({ data }) => {
        commit('updateMarkers', data.payload)
      });
    },

    getCountryCoords({}, country = null){
      return axios.get(`/api/companies/countryCoords?country=${country}`).then(({ data }) => data);
    },
    getTBSTestimonials({ commit }){
      return axios.get(`/api/testimonials/tbs`).then(({ data }) => {
        commit('updateTestimonials', data.payload)
      })
    },
    
    signout({commit, state}){
      commit('removeUser')
    },
    async signin({commit, state},  payloadIn = {}){
      const payload = await axios.post(`/api/auth/login`, payloadIn)
      if(payload.status !== 200) throw _.extend(new Error("Failure to login"), {status: payload.status});
      commit('updateUser', payload.data)
    },
    updateCompany({commit, state},  payload = {}){
      return axios.post(`/api/companies/updateCompany`, payload)
    },
    updateUserPossibleMatched({commit, state},  possibleMatchesDisabled = false){
      return axios.post(`/api/companies/actions/setPossibleMatches`, { possibleMatchesDisabled })
    },
    register({commit, state},  payload = {}){
      return axios.post(`/api/companies`, payload)
    },
    updateContact({commit, state},  payload = {}){
      return axios.post(`/api/companies/updateContact`, payload)
    },
    setAsFavorite({commit, state},  payload = {}){
      return axios.post(`/api/companies/setCompanyAsFavorite`, payload)
    },
    requestPassword({commit, state},  payload = {}){
      return axios.post(`/api/companies/requestPassword`, payload)
    },
    verifyResetToken({commit, state},  payload = {}){
      return axios.post(`/api/companies/verifyResetToken`, payload)
    },
    resetPassword({commit, state},  payload = {}){
      if(payload.token) return axios.post(`/api/companies/resetPasswordWithToken`, payload)
      return axios.post(`/api/companies/resetPassword`, payload)
    },
    getBroadcastSettings({commit, state}){
      return axios.get(`/api/companies/me/broadcastSettings`).then(({ data }) => data.payload);
    },
    updateBroadcastSettings({commit, state}, payload){
      return axios.post(`/api/companies/me/broadcastSettings`, payload)
    },
    getDiamondMembers({}, country = null){
      return axios.get(`/api/companies/diamondMembers`).then(({ data }) => data);
    },
    getGoldMembers({}, country = null){
      return axios.get(`/api/companies/goldMembers`).then(({ data }) => data);
    },
    getTrustedMembers({}, country = null){
      return axios.get(`/api/companies/trustedMembers`).then(({ data }) => data);
    },
    getFavoriteMembers({}, country = null){
      return axios.get(`/api/companies/favoriteMembers`).then(({ data }) => data);
    },
    sendMailToTBS({commit, state},  payload = {}){
      return axios.post(`/api/mail/sendMailToTBS`,payload)
    },
    sendMailToContact({commit, state},  payload = {}){
      return axios.post(`/api/mail/sendMailToContact`,payload)
    },
    sendBroadcastReply({commit, state},  payload = {}){
      return axios.post(`/api/mail/sendBroadcastReply`,payload)
    },
    sendRFQ({commit, state},  payload = {}){
      return axios.post(`/api/mail/sendRFQ`,payload)
    },
    createBroadcast({commit, state},  payload = {}){
      return axios.post(`/api/broadcasts`,payload)
    },
    deleteBroadcast({commit, state},  payload = {}){
      return axios.delete(`/api/broadcasts/${payload.id}`)
    },
    resendBroadcast({commit, state},  payload = {}){
      return axios.post(`/api/broadcasts/${payload.id}/actions/resend`)
    },
    getBroadcastLimit({commit, state},  payload = {}){
      return axios.post(`/api/broadcasts/limit`,payload)
    },
    addPart({commit, state},  payload = {}){
      return axios.post(`/api/inventory`, payload)
    },
    
    updatePart({commit, state},  payload = {}){
      return axios.patch(`/api/inventory/${payload.id}`, payload)
    },
    deletePart({commit, state},  payload = {}){
      return axios.delete(`/api/inventory/${payload.id}`)
    },
    deleteAllParts({commit, state}){
      return axios.post(`/api/inventory/actions/deleteAll`)
    },
    setPageSize({commit, state}, pageSize){
      localStorage.setItem('pageSize', pageSize);
      state.pageSize = pageSize;
    },
    requestInvoice({commit, state},  payload = {}){
      return axios.post(`/api/companies/invoice`,payload)
    },
    uploadLogo({commit, state},  payload = {}){
      return axios.post(`/api/companies/uploadLogo`, payload)
    },
    removeLogo({commit, state}){
      return axios.post(`/api/companies/removeLogo`)
    },
    uploadPicture({commit, state},  payload = {}){
      return axios.post(`/api/companies/uploadPicture`, payload).then(resp => {
        commit('updateProfilePic', resp.data.profilePic)
        return resp
      })
    },
    removePicture({commit, state}){
      return axios.post(`/api/companies/removePicture`).then(resp => {
        commit('removeProfilePic')
        return resp;
      })
    },
    loadProfilePicture({commit, state}){
      return axios.get(`/api/companies/fetchPicture`).then(resp => {
        if(resp.data.profilePic){
          commit('updateProfilePic', resp.data.profilePic)
        } else {
          commit('removeProfilePic')
        }
      })
    },
    logPage({commit, state},  payload = {}){

      // logPage is sometimes called in pre-hook, so we need to make sure user is bootstrapped. (This is done in app.created, which is called later)
      commit('bootstrapUserFromLocalStorage')
      return axios.post(`/api/misc/logPage`, payload)
    },
    recommendCompany({commit, state},  payload = {}){
      const p = _.omit(payload, ['companyId'])
      return axios.post(`/api/companies/${payload.companyId}/actions/recommendCompany`, p)
    },
    deleteRec({commit, state},  payload = {}){
      return axios.post(`/api/companies/me/actions/deleteRecommendation`, payload)
    },
    approveRec({commit, state},  payload = {}){
      return axios.post(`/api/companies/me/actions/approveRecommendation`, payload)
    },
    fetchDoUnapprovedRecsExist({state}){
      return axios.get(`/api/companies/me/actions/doUnapprovedRecommendationsExist`).then(({ data }) => {
        state.doUnapprovedRecsExist = !!data.payload
      });
    },

    // saved searches
    getSavedSearches({commit}){
      return axios.get(`/api/savedSearches`).then(({ data }) => {
        commit('updateSavedSearches', data)
      });
    },
    async switchSavedSearchNotificationType({commit}, item){
      const resp = await axios.post(`/api/savedSearches/${item.id}/actions/updateNotificationType`, { isDigest: !item.isDigest });
      if(resp.data.status !== 200) throw new Error("Error updating saved search")

      await axios.get(`/api/savedSearches`).then(({ data }) => {
        commit('updateSavedSearches', data)
      });
    },
    async removeSavedSearch({commit}, item){
      const resp = await axios.delete(`/api/savedSearches/${item.id}`);
      if(resp.data.status !== 200) throw new Error("Error removing saved search")

      await axios.get(`/api/savedSearches`).then(({ data }) => {
        commit('updateSavedSearches', data)
      });
    },

    async persistSavedSearch({commit}, item){
      const resp = await axios.post(`/api/savedSearches`, item);
      if(resp.data.status !== 200) throw new Error("Error saving saved search")

      await axios.get(`/api/savedSearches`).then(({ data }) => {
        commit('updateSavedSearches', data)
      })
    },

    getLastInvoiceStatus({ commit }, payload = {}) {
      return axios.get(`/api/companies/getLastInvoiceStatus`).then(({ data }) => {
        commit('updateLastInvoiceStatus', data.payload)
      });
    },

    getInvoices({ commit }) {
      return axios.get(`/api/companies/getInvoices`).then(({ data }) => {
        commit('updateInvoices', data.invoices)
        commit('updateInvoicesEBM', data.invoicesEBM)
      });
    },

    getCreditNotes({ commit }) {
      return axios.get(`/api/companies/getCreditNotes`).then(({ data }) => {
        commit('updateCreditNotes', data.creditNotes)
      });
    },

    downloadPDF({}, invoice){
      return axios({
        url: `/api/companies/actions/downloadInvoiceAsPDF/${invoice.invoiceNumber}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice - ${invoice.invoiceNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
    },

    downloadPDFCn({}, creditNote){
      return axios({
        url: `/api/companies/actions/downloadCreditNoteAsPDF/${creditNote.invoiceNumber}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `creditnote - ${creditNote.invoiceNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
    },

    uploadComplaint({}, {companyId, formData}){
      return axios.post(`/api/companies/${companyId}/actions/logComplaint`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      })
    },
    trackGA4Event(context, payload) {
      try {
          const referrer = localStorage.getItem("referrer") || "Direct";

          gtag('event', payload.event, {
              event_category: payload.event_category,
              event_label: payload.event_label,
              action: payload.action,
              message: payload.message,
              membership: payload.membership,
              user_id: payload.user_id || context.state.user?.id || null,
              search_term: payload.search_term || '',
              referrer: referrer || 'Direct',
              external_exit: payload.external_exit,
          });
      } catch (error) {
          throw new Error(error.message);
      }
    }
  },
  modules: {
  }
})

