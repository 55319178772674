<template>
	<div>
		<modal v-if='part' v-show="showModal" @submit="sendMessage" @cancel="closeModal"
			header="Request for quote"
			customCssClasses="sm:max-w-4xl"
			:disableSubmit="!user && !captchaResponse"
			affirmativeButtonLabel='Send'>
			<template v-slot:body>

				<ValidationObserver ref="sendMessageObserver">
					<div class="grid grid-cols-12 gap-6">
						<div class="col-span-12 md:col-span-6">
							<div>
								<div>
									<div class="mt-4 mb-2">
										<h3 class="text-lg font-medium text-gray-900">Item Details</h3>
									</div>
									<dl class='mb-8'>
										<div class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Manufacturer</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{part.manufacturer}}</dd>
										</div>
										<div class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Part Number</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 truncate">{{part.partNumber || '-'}}</dd>
										</div>
										<div class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Description</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{part.description}}</dd>
										</div>
										<div class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Quantity</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{part.quantity}}</dd>
										</div>
										<div class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Condition</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{part.partCondition}}</dd>
										</div>
										<div class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Price</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{Math.round(part.price)}}</dd>
										</div>
									</dl>
								</div>

								<div>
									<div class="mb-2">
										<h3 class="text-lg font-medium text-gray-900">Your Details</h3>
									</div>
									<div class='mb-4 mb-2'>
										<div class='mt-3'>
											<label for="about" class="block text-sm font-medium leading-5 text-gray-700">Company Name</label>
						          <div class="mt-1 rounded-md shadow-sm">
						            <ValidationProvider rules="required" v-slot="{ errors }">
													<input v-model='payload.companyName' class="form-input block w-full">
													<span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
												</ValidationProvider>
						          </div>
						        </div>
						        <div class='mt-3'>
											<label for="about" class="block text-sm font-medium leading-5 text-gray-700">Contact Person</label>
						          <div class="mt-1 rounded-md shadow-sm">
						            <ValidationProvider rules="required" v-slot="{ errors }">
													<input v-model='payload.contactName' class="form-input block w-full">
													<span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
												</ValidationProvider>
						          </div>
						        </div>
						        <div class='mt-3'>
											<label for="about" class="block text-sm font-medium leading-5 text-gray-700">Email Address</label>
						          <div class="mt-1 rounded-md shadow-sm">
						            <ValidationProvider rules="required|email" v-slot="{ errors }">
													<input v-model='payload.contactEmail' class="form-input block w-full">
													<span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
												</ValidationProvider>
						          </div>
						        </div>
						        <div class='mt-3'>
											<label for="about" class="block text-sm font-medium leading-5 text-gray-700">Telephone</label>
						          <div class="mt-1 rounded-md shadow-sm">
						            <ValidationProvider rules="required" v-slot="{ errors }">
													<input v-model='payload.contactTel' class="form-input block w-full">
													<span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
												</ValidationProvider>
						          </div>
						        </div>
						        <div class='mt-3'>
											<label for="about" class="block text-sm font-medium leading-5 text-gray-700">Requested Quantity</label>
						          <div class="mt-1 rounded-md shadow-sm">
						            <ValidationProvider rules="required" v-slot="{ errors }">
													<input type='number' v-model='payload.requestedQuantity' class="form-input block w-full">
													<span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
												</ValidationProvider>
						          </div>
						        </div>
						      </div>
					      </div>

			        </div>
						</div>

						<div class="col-span-12 md:col-span-6">
							<div>
								<div>
									<div class="mt-4 mb-2">
										<h3 class="text-lg font-medium text-gray-900">Item Owner</h3>
									</div>
									<dl class='mb-6'>
										<div class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Company Name</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
												<button class='text-red-700' @click="toCompanyPage">{{part.company.name}}</button>
											</dd>
										</div>
										<div v-if='user' class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Telephone</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
												<a class='text-red-700 hover:underline' :href="companyTelLink">{{part.company.tel}}</a>
											</dd>
										</div>
										<div v-if='user' class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Website</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
												<a v-if='part.company.website && part.company.website !== "-"' class='text-red-700' :href="part.company.website" target="_blank">
													{{part.company.website}}
												</a>
												<div v-else>{{part.company.website}}</div>
											</dd>
										</div>
										<div class=" py-1 sm:grid sm:grid-cols-3 sm:gap-4">
											<dt class="text-sm leading-5 font-medium text-gray-500">Country</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{part.company.country}}</dd>
										</div>
									</dl>
								</div>

								<div class="flex items-center">
									<input id="rfq_sendAll" v-model="payload.sendToAll" type="checkbox" class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out">
			            <label for="rfq_sendAll" class="ml-2 block text-sm leading-5 text-red-700">Send RFQ to all stock holders of this part</label>
			          </div>

								<div class='mt-6'>
									<label for="about" class="block text-sm font-medium leading-5 text-gray-700">Additional Message</label>
				          <div class="mt-1 rounded-md shadow-sm">
										<textarea v-model='payload.message' class="form-input block w-full" rows='8'></textarea>
				          </div>
				        </div>

								<vue-recaptcha v-if='!user' class='mt-6 float-right'
									ref="recaptcha"
									@verify="onVerify"
									:sitekey="recaptchaKey">
								</vue-recaptcha>

			        </div>
						</div>
					</div>
				</ValidationObserver>

		  </template>
		</modal>
	</div>
</template>

<script>

import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import modal from '@/components/modal.vue'
import eventBus from '../utils/eventBus'
import captchaMixin from '@/components/mixins/captcha'

extend('email', {
	 ...email,
  message: 'This field should be a correct email-address'
});
extend('required', {
  ...required,
  message: 'This field is required'
});


export default {
	mixins: [captchaMixin],
  name: 'ModalContact',
  components: {
    modal,
    ValidationProvider,
    ValidationObserver,
	},
  data: () => ({
  	part: null,
  	showModal: false,
  	payload: {
  		companyName: null, 
  		contactName: null, 
  		contactEmail: null, 
  		contactTel: null, 
  		requestedQuantity: null,
  		sendToAll: false, 
  		message: null,
  	},
  }),
  computed: {
  	...mapState(['user']),	
  	companyTelLink(){
			if(this.part && this.part.company) return `tel:${this.part.company.tel}`
			return null;
		}
  }, 
  methods: {
  	...mapActions(["sendRFQ", "getPart"]),
  	resetPayload(){
  		this.payload = {
	  		companyName: this.user ? this.user.companyName : null, 
	  		contactName: this.user ? this.user.name : null, 
	  		contactEmail: this.user ? this.user.email : null, 
	  		contactTel: this.user ? this.user.tel : null, 
	  		requestedQuantity: 1,
	  		sendToAll: false, 
	  		message: this.user ? this.user.signature : "",
	  	}
  	},
  	async openModal(part, isListPage = true){
  		if(isListPage){
  			const resp = await this.getPart(part.id);
  			if(resp.status !== 200) {
  				eventBus.$emit("createSnackbar", {
	   				type: "error", 
	   				title: "Part not found.",
	   				body: "If you feel this is incorrect, please contact The Broker Site",
	   				timeoutInMS: 5000,
	   			})
  				return
  			}
  			part = resp.part;
  		}
  		this.resetPayload();
  		this.part = part; 
  		this.showModal = true;
  	},
  	async sendMessage(){

  		const isValid = await this.$refs.sendMessageObserver.validate();
     	if (!isValid) return;

		const payload = _.extend({
			partId: this.part.id,
			captcha: this.captchaResponse,
		}, this.payload);

  		const resp = await this.sendRFQ(payload);

  		if(resp.data.status !== 200){
   			eventBus.$emit("createSnackbar", {
   				type: "error", 
   				title: "Something went wrong",
   				body: "Message delivery failed. Please try again. <br/> Reason: " + resp.data.payload.msg,
   				timeoutInMS: 5000,
   			})
   		} else {
   			eventBus.$emit("createSnackbar", {
   				type: "success", 
   				title: `Request for quote send to ${resp.data.count === 1 ? "1 company" : resp.data.count + " companies"}`,
   			})
  			this.closeModal();
   		}
  	},
  	closeModal(){
  		this.showModal = false;

  		// Reset on next frame
  		// https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#validate-before-submit
      requestAnimationFrame(() => {
        this.$refs.sendMessageObserver.reset();
      });
  	},
  	toCompanyPage(){
			this.$router.push({path: `/companies/${this.part.company.id}` });
		},
  }
}
</script>
<style>

</style>
